define("discourse/plugins/discourse-ai/discourse/components/ai-search-discoveries", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/runloop", "@ember/service", "discourse/components/cook-text", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/decorators", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/components/ai-blinking-animation", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _willDestroy, _runloop, _service, _cookText, _dButton, _ajax, _decorators, _discourseI18n, _aiBlinkingAnimation, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DISCOVERY_TIMEOUT_MS = 10000;
  const BUFFER_WORDS_COUNT = 50;
  function setUpBuffer(discovery, bufferTarget) {
    const paragraphs = discovery.split(/\n+/);
    let wordCount = 0;
    const paragraphBuffer = [];
    for (const paragraph of paragraphs) {
      const wordsInParagraph = paragraph.split(/\s+/);
      wordCount += wordsInParagraph.length;
      if (wordCount >= bufferTarget) {
        paragraphBuffer.push(paragraph.concat("..."));
        return paragraphBuffer.join("\n");
      } else {
        paragraphBuffer.push(paragraph);
        paragraphBuffer.push("\n");
      }
    }
    return null;
  }
  class AiSearchDiscoveries extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "search", [_service.service]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "messageBus", [_service.service]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "discobotDiscoveries", [_service.service]))();
    #discobotDiscoveries = (() => (dt7948.i(this, "discobotDiscoveries"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "loadingDiscoveries", [_tracking.tracked], function () {
      return false;
    }))();
    #loadingDiscoveries = (() => (dt7948.i(this, "loadingDiscoveries"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "hideDiscoveries", [_tracking.tracked], function () {
      return false;
    }))();
    #hideDiscoveries = (() => (dt7948.i(this, "hideDiscoveries"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "fulldiscoveryToggled", [_tracking.tracked], function () {
      return false;
    }))();
    #fulldiscoveryToggled = (() => (dt7948.i(this, "fulldiscoveryToggled"), void 0))();
    discoveryTimeout = null;
    async _updateDiscovery(update) {
      if (this.query === update.query) {
        if (this.discoveryTimeout) {
          (0, _runloop.cancel)(this.discoveryTimeout);
        }
        if (!this.discobotDiscoveries.discoveryPreview) {
          const buffered = setUpBuffer(update.ai_discover_reply, BUFFER_WORDS_COUNT);
          if (buffered) {
            this.discobotDiscoveries.discoveryPreview = buffered;
            this.loadingDiscoveries = false;
          }
        }
        this.discobotDiscoveries.modelUsed = update.model_used;
        this.discobotDiscoveries.discovery = update.ai_discover_reply;
        // Handling short replies.
        if (update.done) {
          if (!this.discobotDiscoveries.discoveryPreview) {
            this.discobotDiscoveries.discoveryPreview = update.ai_discover_reply;
          }
          this.discobotDiscoveries.discovery = update.ai_discover_reply;
          this.loadingDiscoveries = false;
        }
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "_updateDiscovery", [_decorators.bind]))();
    unsubscribe() {
      this.messageBus.unsubscribe("/discourse-ai/ai-bot/discover", this._updateDiscovery);
    }
    static #_8 = (() => dt7948.n(this.prototype, "unsubscribe", [_decorators.bind]))();
    subscribe() {
      this.messageBus.subscribe("/discourse-ai/ai-bot/discover", this._updateDiscovery);
    }
    static #_9 = (() => dt7948.n(this.prototype, "subscribe", [_decorators.bind]))();
    get query() {
      return this.args?.searchTerm || this.search.activeGlobalSearchTerm;
    }
    get toggleLabel() {
      if (this.fulldiscoveryToggled) {
        return "discourse_ai.discobot_discoveries.collapse";
      } else {
        return "discourse_ai.discobot_discoveries.tell_me_more";
      }
    }
    get toggleIcon() {
      if (this.fulldiscoveryToggled) {
        return "chevron-up";
      } else {
        return "";
      }
    }
    get toggleMakesSense() {
      return this.discobotDiscoveries.discoveryPreview && this.discobotDiscoveries.discoveryPreview !== this.discobotDiscoveries.discovery;
    }
    async triggerDiscovery() {
      if (this.discobotDiscoveries.lastQuery === this.query) {
        this.hideDiscoveries = false;
        return;
      } else {
        this.discobotDiscoveries.resetDiscovery();
      }
      this.hideDiscoveries = false;
      this.loadingDiscoveries = true;
      this.discoveryTimeout = (0, _runloop.later)(this, this.timeoutDiscovery, DISCOVERY_TIMEOUT_MS);
      try {
        this.discobotDiscoveries.lastQuery = this.query;
        await (0, _ajax.ajax)("/discourse-ai/ai-bot/discover", {
          data: {
            query: this.query
          }
        });
      } catch {
        this.hideDiscoveries = true;
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "triggerDiscovery", [_object.action]))();
    toggleDiscovery() {
      this.fulldiscoveryToggled = !this.fulldiscoveryToggled;
    }
    static #_11 = (() => dt7948.n(this.prototype, "toggleDiscovery", [_object.action]))();
    timeoutDiscovery() {
      this.loadingDiscoveries = false;
      this.discobotDiscoveries.discoveryPreview = "";
      this.discobotDiscoveries.discovery = "";
      this.discobotDiscoveries.discoveryTimedOut = true;
    }
    static #_12 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="ai-search-discoveries"
          {{didInsert this.subscribe}}
          {{didInsert this.triggerDiscovery this.query}}
          {{willDestroy this.unsubscribe}}
        >
          <div class="ai-search-discoveries__completion">
            {{#if this.loadingDiscoveries}}
              <AiBlinkingAnimation />
            {{else if this.discobotDiscoveries.discoveryTimedOut}}
              {{i18n "discourse_ai.discobot_discoveries.timed_out"}}
            {{else}}
              {{#if this.fulldiscoveryToggled}}
                <div class="ai-search-discoveries__discovery-full cooked">
                  <CookText @rawText={{this.discobotDiscoveries.discovery}} />
                </div>
              {{else}}
                <div class="ai-search-discoveries__discovery-preview cooked">
                  <CookText
                    @rawText={{this.discobotDiscoveries.discoveryPreview}}
                  />
                </div>
              {{/if}}
    
              {{#if this.toggleMakesSense}}
                <DButton
                  class="btn-flat btn-text ai-search-discoveries__toggle"
                  @label={{this.toggleLabel}}
                  @icon={{this.toggleIcon}}
                  @action={{this.toggleDiscovery}}
                />
              {{/if}}
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "bR6D+n2J",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"ai-search-discoveries\"],[4,[32,0],[[30,0,[\"subscribe\"]]],null],[4,[32,0],[[30,0,[\"triggerDiscovery\"]],[30,0,[\"query\"]]],null],[4,[32,1],[[30,0,[\"unsubscribe\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"ai-search-discoveries__completion\"],[12],[1,\"\\n\"],[41,[30,0,[\"loadingDiscoveries\"]],[[[1,\"          \"],[8,[32,2],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"discobotDiscoveries\",\"discoveryTimedOut\"]],[[[1,\"          \"],[1,[28,[32,3],[\"discourse_ai.discobot_discoveries.timed_out\"],null]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"fulldiscoveryToggled\"]],[[[1,\"            \"],[10,0],[14,0,\"ai-search-discoveries__discovery-full cooked\"],[12],[1,\"\\n              \"],[8,[32,4],null,[[\"@rawText\"],[[30,0,[\"discobotDiscoveries\",\"discovery\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,0],[14,0,\"ai-search-discoveries__discovery-preview cooked\"],[12],[1,\"\\n              \"],[8,[32,4],null,[[\"@rawText\"],[[30,0,[\"discobotDiscoveries\",\"discoveryPreview\"]]]],null],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"toggleMakesSense\"]],[[[1,\"            \"],[8,[32,5],[[24,0,\"btn-flat btn-text ai-search-discoveries__toggle\"]],[[\"@label\",\"@icon\",\"@action\"],[[30,0,[\"toggleLabel\"]],[30,0,[\"toggleIcon\"]],[30,0,[\"toggleDiscovery\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"]],[]]]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-search-discoveries.js",
      "scope": () => [_didInsert.default, _willDestroy.default, _aiBlinkingAnimation.default, _discourseI18n.i18n, _cookText.default, _dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiSearchDiscoveries;
});